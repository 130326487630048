import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { CounterBoxingFitComponent } from './counter.component';

@Component({
  selector: 'app-boxing-fit',
  templateUrl: './boxing-fit.component.html',
  styleUrls: ['./boxing-fit.component.css']
})
export class BoxingFitComponent implements OnInit {

  version = '0.0.2';
  @ViewChild('counterBoxingFit', {read: CounterBoxingFitComponent, static: true})
  private counter: CounterBoxingFitComponent;
  counterState = 'counter has stopped';

  autoTicks = false;
  disabled = false;
  invert = false;
  showTicks = true;

  thumbLabel = true;
  vertical = false;
  // for Roundds
  roundstep = 1;
  roundmax = 6;
  roundmin = 3;
  roundvalue = 3;

  passivestep = 5;
  passivemax = 60;
  passivemin = 10;
  passivevalue = 35;

  activestep = 5;
  activemax = 30;
  activemin = 10;
  activevalue = 10;

  // for Delay

  get tickInterval(): number | 'auto' {
    return this.showTicks ? (this.autoTicks ? 'auto' : this._tickInterval) : 0;
  }
  set tickInterval(value) {
    this._tickInterval = coerceNumberProperty(value);
  }
  private _tickInterval = 1;

  constructor() {}

   ngOnInit() {
  }

  public exercise(roundvalue, passivevalue, activevalue) {
    console.log('starting exercise');
    console.log('round time:' + roundvalue);
    console.log('passivevalue :' + passivevalue);
    console.log('activevalue: ' + activevalue);

    this.counter.startAt = (roundvalue * passivevalue) + (roundvalue * activevalue);
    this.counter.counterState.subscribe((msg) => {
      if ( msg === 'COMPLETE') {
        this.counterState = 'counter has stopped';
      }
    });
    this.counter.start(roundvalue, passivevalue, activevalue);
    this.counterState = 'counter is ticking';
    // this.comboset;
  }

  public stopExercise() {
    this.counter.stop();
    this.counterState = 'counter has stopped';
    this.counter.flow = '';
  }

}

import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Observable, interval, Subscription } from 'rxjs';
import { take, finalize } from 'rxjs/operators';
import 'rxjs/Rx';


@Component({
    selector: 'counter,comboset',
    template: `<div>
                <span>{{currentValue}}</span>
              </div><br /><br />
              <p *ngIf="combo">- {{combo}} -</p>
              `,
    styleUrls: ['./boxing.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})


  export class CounterComponent  {
    @Input()
    startAt = 1;

    @Input()
    showTimeRemaining = true;

    @Output()
    counterState = new EventEmitter();

    public currentValue = '00:00';
    public combo = '';

    private currentSubscription: Subscription;
    private audioSubscription: Subscription;

    constructor(private changeDetector: ChangeDetectorRef) { }

    public start(moveset, delayvalue) {
      const i = new Audio();
      this.playBellAudio(i);
      this.currentValue = this.formatValue(this.startAt);
      this.changeDetector.detectChanges();
      const t: Observable<number> = interval(1000);
      const u: Observable<number> = interval(moveset * 700 + (delayvalue * 1000));
      this.currentSubscription = t.pipe(
        finalize(() => {
          setTimeout(() => {
            this.playBellAudio(i);
          }, (moveset * 700));
        }),
        take(this.startAt)).map(v => this.startAt - (v + 1)).subscribe(v => {
          this.currentValue = this.formatValue(v);
          this.changeDetector.detectChanges();
        }, err => {
          this.counterState.error(err);
        }, () => {
          this.currentSubscription.unsubscribe();
          this.currentValue = '00:00';
          this.counterState.emit('COMPLETE');
          this.changeDetector.detectChanges();
        });

      this.audioSubscription = u.pipe(take(this.startAt * (1000 / ((moveset * 700) + (delayvalue * 1000))))).subscribe(w => {
          this.combo = this.comboSets(moveset);
          this.playAudio(i, this.combo);
          // console.log(this.combo);
          // console.log('w ' + w);
          }, err => {
            console.log(err);
          }, () => {
            this.audioSubscription.unsubscribe();
            // this.playAudio(i, this.combo);
            // console.log(this.combo);
        });


    }

    private formatValue(v) {
    const minutes = Math.floor(v / 60);
    const formattedMinutes = '' + (minutes > 9 ? minutes : '0' + minutes);
    const seconds = v % 60;
    const formattedSeconds = '' + (seconds > 9 ? seconds : '0' + seconds);
    return `${formattedMinutes}:${formattedSeconds}`;
    }

    public stop() {
        this.currentSubscription.unsubscribe();
        this.counterState.emit('ABORTED');
        this.audioSubscription.unsubscribe();
    }

    public delay = (amount) => {
      return new Promise((resolve) => {
        setTimeout(resolve, amount);
      });
    }

    public playBellAudio(i) {
      i.src = '/assets/boxingbell.wav';
      i.load();
      i.play();
    }

    public playAudio(i, punch) {
      console.log(punch);
      i.src = '/assets/' + punch + '.wav';
      i.load();
      i.play();
      /* punch.forEach((p, index) => {
        setTimeout(() => {
          if (p===1){
          i.src = "/assets/1.wav";
          } else if (p===2){
            i.src = "/assets/2.wav";
          } else if (p===3){
            i.src = "/assets/3.wav";
          } else if (p===4){
            i.src = "/assets/4.wav";
          } else if (p===5){
            i.src = "/assets/5.wav";
          } else if (p===6){
            i.src = "/assets/6.wav";
          } else if (p===0){
            i.src = "/assets/0.wav";
          }
          i.load();
          i.play();
          // console.log('debug ' + p);
        }, 500*index)
      });*/
    }

    public comboSets(moveset) {
      if (moveset === 3 ) {
        const list: any[] = [
          '112',
          '123',
          '254',
          '163',
          '164',
          '1dr2',
          '2dl3',
          '111',
          'dl12',
          '1sl2',
          '2dr4',
          '153',
          '264'
        ];
        return list[Math.floor(Math.random() * Math.floor(list.length))];
      } else if (moveset === 4) {
        const list: any[] = [
          '1121',
          '1352',
          '2123',
          '2145',
          '12dl3',
          '1sl52',
          '2dl52',
          '13dr6'
          ];
        return list[Math.floor(Math.random() * Math.floor(list.length))];
      } else if (moveset === 5) {
        const list: any[] = [
          '12sl32',
          '12632',
          '21dl52'
        ];
        return list[Math.floor(Math.random() * Math.floor(list.length))];
      } else if (moveset === 6) {
        const list: any[] = [
          '112112',
          '12sr232',
          '12dr325',
          '111232',
          '232112',
          '123412',
          '12srsl12',
          '121134',
          '1sr4dr32'
        ];
        return list[Math.floor(Math.random() * Math.floor(list.length))];
      }
    }

  }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BoxingComponent } from './boxing/boxing.component';
import { CreditsComponent } from './credits/credits.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { BoxingFitComponent } from './boxing-fit/boxing-fit.component';
import { HomeComponent } from './home/home.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'combo-training', component: BoxingComponent },
  { path: 'credits', component: CreditsComponent },
  { path: 'tutorial', component: TutorialComponent },
  { path: 'boxing-fit', component: BoxingFitComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

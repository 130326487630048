import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSliderModule} from '@angular/material/slider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BoxingComponent } from './boxing/boxing.component';
import { BoxingFitComponent } from './boxing-fit/boxing-fit.component';
import { CounterComponent } from './boxing/counter.component';
import { CounterBoxingFitComponent } from './boxing-fit/counter.component';
import { CreditsComponent } from './credits/credits.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { HomeComponent } from './home/home.component';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    BoxingComponent,
    BoxingFitComponent,
    CounterComponent,
    CounterBoxingFitComponent,
    CreditsComponent,
    TutorialComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatSliderModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    NgbModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  // schemas: [
  //  CUSTOM_ELEMENTS_SCHEMA,
  //  NO_ERRORS_SCHEMA
  // ]
})
export class AppModule { }

import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Observable, interval, Subscription } from 'rxjs';
import { take, finalize } from 'rxjs/operators';
import 'rxjs/Rx';


@Component({
    selector: 'counterBoxingFit',
    template: `<div>
                <span>{{currentValue}}</span>
              </div><br /><br />
              {{ flow }}
              `,
    styleUrls: ['./boxing-fit.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

  export class CounterBoxingFitComponent {
    @Input()
    startAt = 1;

    @Input()
    showTimeRemaining = true;

    @Output()
    counterState = new EventEmitter();

    public currentValue = '00:00';
    public flow = '';
    private currentSubscription: Subscription;
    constructor(private changeDetector: ChangeDetectorRef) { }

    public start(roundvalue, passivevalue, activevalue) {
      const i = new Audio();
      this.currentValue = this.formatValue(this.startAt);
      this.changeDetector.detectChanges();
      const t: Observable<number> = interval(1000);
      this.currentSubscription = t.pipe(
        finalize(() => {
          setTimeout(() => {
            this.playBellAudio(i);
          }, (200));
        }),
        take(this.startAt)).
        map(v => this.startAt - (v + 1)).subscribe(v => {
          this.currentValue = this.formatValue(v);
          this.changeDetector.detectChanges();
          console.log(v);
          if ( v % (passivevalue + activevalue) > activevalue ) {
            if (this.flow !== 'easy') {
              this.flow = 'easy';
              console.log(this.flow);
              this.playAudio(i, 'easy');
            }
          } else {
            if (this.flow !== 'work') {
              this.flow = 'work';
              console.log(this.flow);
              this.playAudio(i, 'work');
            }
          }
        }, err => {
          this.counterState.error(err);
        }, () => {
          this.currentSubscription.unsubscribe();
          this.currentValue = '00:00';
          this.counterState.emit('COMPLETE');
          this.changeDetector.detectChanges();
        });
    }

    private formatValue(v) {
    const minutes = Math.floor(v / 60);
    const formattedMinutes = '' + (minutes > 9 ? minutes : '0' + minutes);
    const seconds = v % 60;
    const formattedSeconds = '' + (seconds > 9 ? seconds : '0' + seconds);
    return `${formattedMinutes}:${formattedSeconds}`;
    }

    public stop() {
        this.currentSubscription.unsubscribe();
        this.counterState.emit('ABORTED');
    }

    public playBellAudio(i) {
      i.src = '/assets/boxingbell.wav';
      i.load();
      i.play();
    }

    public playAudio(i, move) {
      i.src = '/assets/' + move + '.wav';
      i.load();
      i.play();
    }

  }

import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { CounterComponent } from './counter.component';


@Component({
  selector: 'app-boxing',
  templateUrl: './boxing.component.html',
  styleUrls: ['./boxing.component.css']
})
export class BoxingComponent implements OnInit {
  version = '0.0.18';
  // @ViewChild('counter') counter: CounterComponent;
  @ViewChild('counter', {read: CounterComponent, static: true})
  private counter: CounterComponent;

  @ViewChild('comboset', {read: CounterComponent, static: true})
  private comboset: CounterComponent;
  counterState = 'counter has stopped';


  autoTicks = false;
  disabled = false;
  invert = false;
  showTicks = true;

  thumbLabel = true;
  vertical = false;
  // for Roundds
  roundstep = 30;
  roundmax = 600;
  roundmin = 30;
  roundvalue = 120;

  movestep = 1;
  movemax = 6;
  movemin = 3;
  movevalue = 3;

  delaystep = 0.5;
  delaymax = 6;
  delaymin = 0.5;
  delayvalue = 3;

  // for Delay

  get tickInterval(): number | 'auto' {
    return this.showTicks ? (this.autoTicks ? 'auto' : this._tickInterval) : 0;
  }
  set tickInterval(value) {
    this._tickInterval = coerceNumberProperty(value);
  }
  private _tickInterval = 1;

  constructor() {}

   ngOnInit() {
  }

  public exercise(roundvalue, movevalue, delayvalue) {
    console.log('starting exercise');
    console.log('round time:' + roundvalue);
    console.log('moveset :' + movevalue);
    console.log('delay: ' + delayvalue);

    this.counter.startAt = roundvalue;
    this.counter.counterState.subscribe((msg) => {
      if ( msg === 'COMPLETE') {
        this.counterState = 'counter has stopped';
      }
    });
    this.counter.start(movevalue, delayvalue);
    this.counterState = 'counter is ticking';
    // this.comboset;
  }

  public stopExercise() {
    this.counter.stop();
    this.counterState = 'counter has stopped';
  }

}
